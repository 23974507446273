
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import { statusColor } from "@/core/services/HelpersFun";
import DateHandler from "@/components/DateHandler.vue";
export default defineComponent({
  name: "api-user-history",
  emit: ["pageNumber"],
  components: {
    DateHandler,
  },
  props: {
    list: {
      type: Object,
      default: null,
    },
  },
  setup(props, { emit }) {
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };

    const adminMode = ref(true);
    const selectedId = ref(null);
    const payload_dialog = ref(false);

    const changePage = (val: number) => {
      emit("pageNumber", props.list?.pager_name, val);
    };

    return {
      translate,
      statusColor,
      selectedId,
      adminMode,
      changePage,
      payload_dialog,
    };
  },
});
