
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import BulkMessagesAndApiChart from "@/components/BulkMessagesAndApiChart.vue";
export default defineComponent({
  name: "api-user-history",
  props: {
    list: {
      type: Object,
      default: null,
    },
    notifications_chart: {
      type: Object,
      default: null,
    },
  },
  components: {
    BulkMessagesAndApiChart,
  },
  setup() {
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };

    return {
      translate,
    };
  },
});
