
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { statusColor } from "@/core/services/HelpersFun";
import UserAvatar from "@/components/UserAvatar.vue";
export default defineComponent({
  name: "api-user-history",
  components: {
    UserAvatar,
  },
  props: {
    list: {
      type: Object,
      default: null,
    },
    transactionStatus: {
      type: Object,
      default: null,
    },
    listLogs: {
      type: Object,
      default: null,
    },
  },

  setup() {
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };

    return {
      translate,
      statusColor,
    };
  },
});
