import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header card-header-stretch" }
const _hoisted_3 = { class: "d-flex align-items-center" }
const _hoisted_4 = { class: "d-flex justify-content-start flex-column" }
const _hoisted_5 = { class: "mb-1 fs-7 text-nowrap" }
const _hoisted_6 = { class: "text-muted fw-bold d-block fs-8 text-nowrap" }
const _hoisted_7 = { class: "card-toolbar" }
const _hoisted_8 = { class: "nav nav-tabs nav-line-tabs nav-stretch fs-5 border-0" }
const _hoisted_9 = ["href", "onClick", "id"]
const _hoisted_10 = { class: "card-body" }
const _hoisted_11 = {
  class: "tab-content",
  id: "myTabContent"
}
const _hoisted_12 = ["id"]
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { key: 1 }
const _hoisted_15 = { key: 2 }
const _hoisted_16 = { key: 3 }
const _hoisted_17 = { key: 4 }
const _hoisted_18 = { key: 5 }
const _hoisted_19 = { key: 6 }
const _hoisted_20 = { key: 7 }
const _hoisted_21 = { key: 8 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserAvatar = _resolveComponent("UserAvatar")!
  const _component_mainInformation = _resolveComponent("mainInformation")!
  const _component_MessagesHistory = _resolveComponent("MessagesHistory")!
  const _component_BotsHistory = _resolveComponent("BotsHistory")!
  const _component_SecretaryHistory = _resolveComponent("SecretaryHistory")!
  const _component_ApiHistory = _resolveComponent("ApiHistory")!
  const _component_SupportHistory = _resolveComponent("SupportHistory")!
  const _component_FinancialHistory = _resolveComponent("FinancialHistory")!
  const _component_BotsLogHistory = _resolveComponent("BotsLogHistory")!
  const _component_permissions = _resolveComponent("permissions")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.dataHolder.data.main_information)
          ? (_openBlock(), _createBlock(_component_UserAvatar, {
              key: 0,
              avatar: _ctx.dataHolder.data.main_information?.user?.picture,
              size: "40px",
              id: _ctx.dataHolder.data.main_information?.user?.id
            }, null, 8, ["avatar", "id"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.dataHolder.data.main_information?.user?.first_name) + " " + _toDisplayString(_ctx.dataHolder.data.main_information?.user?.last_name), 1),
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.dataHolder.data.main_information?.user?.mobile), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("ul", _hoisted_8, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataHolder.tabs, (item, i) => {
            return (_openBlock(), _createElementBlock("li", {
              key: i,
              class: "nav-item"
            }, [
              _createElementVNode("a", {
                class: _normalizeClass(["nav-link", 
                _ctx.dataHolder.requestedData.includes(item.name) ? 'active' : ''
              ]),
                "data-bs-toggle": "tab",
                href: `#kt_tab_pane_${item.name}`,
                onClick: ($event: any) => (_ctx.handleFetch(item.name)),
                id: item.name
              }, _toDisplayString(_ctx.translate(item.value)), 11, _hoisted_9)
            ]))
          }), 128))
        ])
      ])
    ]),
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataHolder.tabs, (item, i) => {
          return (_openBlock(), _createElementBlock("div", {
            key: i,
            class: _normalizeClass(["tab-pane fade", 
            _ctx.dataHolder.requestedData.includes(item.name) ? 'show active' : ''
          ]),
            id: `kt_tab_pane_${item.name}`,
            role: "tabpanel"
          }, [
            (item.name === 'main_information')
              ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                  (_ctx.dataHolder.data.main_information)
                    ? (_openBlock(), _createBlock(_component_mainInformation, {
                        key: 0,
                        data: _ctx.dataHolder.data.main_information.user
                      }, null, 8, ["data"]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            (item.name === 'messages')
              ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                  (_ctx.dataHolder.data.messages)
                    ? (_openBlock(), _createBlock(_component_MessagesHistory, {
                        key: 0,
                        list: _ctx.dataHolder.data.messages.messages,
                        "statistics-data": _ctx.dataHolder.data.messages.messages_statistics
                      }, null, 8, ["list", "statistics-data"]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            (item.name === 'bots')
              ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                  (_ctx.dataHolder.data.bots)
                    ? (_openBlock(), _createBlock(_component_BotsHistory, {
                        key: 0,
                        "bot-list": _ctx.dataHolder.data.bots.bots
                      }, null, 8, ["bot-list"]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            (item.name === 'secretary')
              ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                  (_ctx.dataHolder.data.secretary)
                    ? (_openBlock(), _createBlock(_component_SecretaryHistory, {
                        key: 0,
                        list: _ctx.dataHolder.data.secretary.secretaries
                      }, null, 8, ["list"]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            (item.name === 'api')
              ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                  (_ctx.dataHolder.data.api)
                    ? (_openBlock(), _createBlock(_component_ApiHistory, {
                        key: 0,
                        list: _ctx.dataHolder.data.api.notifications,
                        notifications_chart: _ctx.dataHolder.data.api.notifications_chart
                      }, null, 8, ["list", "notifications_chart"]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            (item.name === 'support_history')
              ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                  (_ctx.dataHolder.data.support_history)
                    ? (_openBlock(), _createBlock(_component_SupportHistory, {
                        key: 0,
                        list: _ctx.dataHolder.data.support_history.tickets,
                        chat_messages: _ctx.dataHolder.data.support_history.chat_messages,
                        tasks: _ctx.dataHolder.data.support_history.tasks
                      }, null, 8, ["list", "chat_messages", "tasks"]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            (item.name === 'financial')
              ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                  (_ctx.dataHolder.data.financial)
                    ? (_openBlock(), _createBlock(_component_FinancialHistory, {
                        key: 0,
                        list: _ctx.dataHolder.data.financial.transactions.transactions.data,
                        transactionStatus: _ctx.dataHolder.data.financial.transactions,
                        listLogs: _ctx.dataHolder.data.financial.financial_logs
                      }, null, 8, ["list", "transactionStatus", "listLogs"]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            (item.name === 'bots_log')
              ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                  (_ctx.dataHolder.data.bots_log)
                    ? (_openBlock(), _createBlock(_component_BotsLogHistory, {
                        key: 0,
                        list: _ctx.dataHolder.data.bots_log,
                        onPageNumber: _ctx.changePage
                      }, null, 8, ["list", "onPageNumber"]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            (item.name === 'permissions')
              ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                  _createVNode(_component_permissions, { user_permissions: _ctx.user_permissions }, null, 8, ["user_permissions"])
                ]))
              : _createCommentVNode("", true)
          ], 10, _hoisted_12))
        }), 128))
      ])
    ])), [
      [_directive_loading, _ctx.loading]
    ])
  ]))
}