
import { defineComponent, onMounted, reactive, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import UserAvatar from "@/components/UserAvatar.vue";
import mainInformation from "@/views/pages/Admin/users/historyComponents/mainInformation.vue";
import permissions from "@/views/pages/Admin/users/historyComponents/permissions.vue";
import MessagesHistory from "@/views/pages/Admin/users/historyComponents/MessagesHistory.vue";
import BotsHistory from "@/views/pages/Admin/users/historyComponents/BotsHistory.vue";
import SecretaryHistory from "@/views/pages/Admin/users/historyComponents/SecretaryHistory.vue";
import ApiHistory from "@/views/pages/Admin/users/historyComponents/ApiHistory.vue";
import SupportHistory from "@/views/pages/Admin/users/historyComponents/SupportHistory.vue";
import FinancialHistory from "@/views/pages/Admin/users/historyComponents/FinancialHistory.vue";
import BotsLogHistory from "@/views/pages/Admin/users/historyComponents/BotsLogHistory.vue";
import { useRoute } from "vue-router";
export default defineComponent({
  name: "user-history-data",
  components: {
    UserAvatar,
    mainInformation,
    MessagesHistory,
    BotsHistory,
    SecretaryHistory,
    ApiHistory,
    SupportHistory,
    FinancialHistory,
    BotsLogHistory,
    permissions,
  },
  props: {
    userId: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };

    const loading = ref(false);
    const store = useStore();
    const route = useRoute();
    const userId = ref(route.params.id);
    const dataHolder = reactive({
      data: [] as any,
      requestedData: ["main_information"],
      pages: {
        bot_log_page: 1,
      },
      tabs: [
        { name: "main_information", value: "main_information" },
        { name: "permissions", value: "permissions" },
        { name: "bots", value: "subscriptions" },
        { name: "messages", value: "bulk-message" },
        { name: "secretary", value: "secretary" },
        { name: "api", value: "api" },
        { name: "financial", value: "financial" },
        { name: "support_history", value: "support_history" },
        { name: "bots_log", value: "bots_log" },
      ],
    });
    const user_permissions = ref()

    const fetchData = () => {
      loading.value = true;
      let url = `admin/user/${userId.value}/info?with_platforms=true&with_commission=
      true&bot_log_page=${dataHolder.pages.bot_log_page}&`;

      dataHolder.requestedData.forEach((data, index) => {
        url += `requested_data[${index}]=${data}&`;
      });
      url = url.slice(0, -1); // حذف آخرین علامت '&' از URL

      ApiService.get(url)
        .then(({ data }) => {
          
          user_permissions.value = data.data.main_information.user.permissions
          
          dataHolder.requestedData.forEach((request) => {
            dataHolder.data[request] = data.data[request];
          });
        })
        .catch(({ response }) => {
          store.commit(Mutations.SET_ERROR, response.data);
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const handleFetch = (request) => {
      if (!dataHolder.requestedData.includes(request)) {
        dataHolder.requestedData.push(request);
        fetchData();
      }
    };

    onMounted(() => {
      
      if(route.query.type==='per'){
        let x = document.getElementById('permissions')
       x?.click()
      }
      fetchData();
    });

    const changePage = (pageName, pageNumber) => {
      dataHolder.pages[pageName] = pageNumber;

      fetchData();
    };

    return {
      translate,
      dataHolder,
      handleFetch,
      loading,
      changePage,
      user_permissions,
    };
  },
});
