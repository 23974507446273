
import { defineComponent } from "vue";
import { translate } from "@/core/services/HelpersFun";

export default defineComponent({
  name: "message-out",
  props: {
    name: String,
    image: String,
    time: Object,
    text: String,
    user_type: String,
    file: Object,
    status: String,
    showAvatar: {
      default: false,
      type: Boolean,
    },
  },
  setup() {
    const handleStatus = (status) => {
      // if (!props.status) return;
      if (status === "pending") return "clock-history";
      if (status === "send") return "check-all";
      return null;
    };

    return {
      handleStatus,
      translate,
    };
  },
});
