
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import { statusColor } from "@/core/services/HelpersFun";
import ShowTicketBodyModal from "@/views/pages/other/ticket/component/ShowTicketBodyModal.vue";
import MessageIn from "@/components/messenger-parts/MessageIn.vue";
import MessageOut from "@/components/messenger-parts/MessageOut.vue";
export default defineComponent({
  name: "api-user-history",
  props: {
    list: {
      type: Object,
      default: null,
    },
    chat_messages: {
      type: Object,
      default: null,
    },
    tasks: {
      type: Object,
      default: null,
    },
  },
  components: {
    ShowTicketBodyModal,
    MessageIn,
    MessageOut,
  },
  setup() {
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };

    const adminMode = ref(true);
    const selectedId = ref(null);

    return {
      translate,
      statusColor,
      selectedId,
      adminMode,
    };
  },
});
