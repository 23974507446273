
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { checkPackagePlatform } from "@/core/services/HelpersFun";
import Platform from "@/components/Platform.vue";
import JwtService from "@/core/services/JwtService";
import BulkStatusWidget from "@/views/pages/services/bulkMessage/component/BulkStatusWidget.vue";
import MultiMessagesReportModal from "@/views/pages/services/bulkMessage/component/MultiMessagesReports.vue";

export default defineComponent({
  name: "messages-user-history",
  props: {
    list: {
      type: Object,
      default: null,
    },
    statisticsData: {
      type: Object,
      default: null,
    },
  },
  components: {
    Platform,
    BulkStatusWidget,
    MultiMessagesReportModal,
  },
  setup() {
    const messageId = ref();
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };

    const excelLink = (id, param) => {
      return (
        process.env.VUE_APP_BACK_URL +
        `message/${id}/report/download?status=${param}&api_token=${JwtService.getToken()}`
      );
    };

    const route = useRoute();
    return {
      translate,
      route,
      excelLink,
      checkPackagePlatform,
      messageId,
    };
  },
});
