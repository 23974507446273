
import { defineComponent } from "vue";
import { translate } from "@/core/services/HelpersFun";
export default defineComponent({
  name: "message-out",
  props: {
    image: String,
    time: Object,
    text: String,
    name: String,
    user_type: String,
    file: Object,
  },
  setup() {
    return {
      translate,
    };
  },
});
