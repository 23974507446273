
import { defineComponent, onMounted, ref, watch } from "vue";
import contacts from "@/core/data/contacts";
import MessageIn from "@/components/messenger-parts/MessageIn.vue";
import MessageOut from "@/components/messenger-parts/MessageOut.vue";
import { useStore } from "vuex";
import ApiService from "@/core/services/ApiService";
import { statusColor } from "@/core/services/HelpersFun";
import UserAvatar from "@/components/UserAvatar.vue";
import { Mutations } from "@/store/enums/StoreEnums";
import { useI18n } from "vue-i18n";
import { hideModal, showModal } from "@/core/helpers/dom";
import { useRoute } from "vue-router";
import JwtService from "@/core/services/JwtService";

export default defineComponent({
  name: "show-ticket-body-modal",
  components: {
    MessageIn,
    MessageOut,
    UserAvatar,
  },
  emits: ["refreshTickets"],
  props: {
    admin: {
      type: Boolean,
      default: false,
    },
    ticketId: {
      type: String,
      default: null,
    },
  },
  setup(props, { emit }) {
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };
    const route = useRoute();
    const modalRef = ref<HTMLElement | null>(null);
    const store = useStore();
    const loading = ref(true);
    const el = ref<HTMLElement | null>(null);
    const submitButton1 = ref<HTMLElement | null>(null);
    const submitButton2 = ref<HTMLElement | null>(null);
    const list = ref();
    const currentUser = ref(store.getters.currentUser);
    const adminMode = ref(false);

    const newMessageText = ref("");

    const permission = ref();
    const userType = ref();

    onMounted(() => {
      setTimeout(() => {
        let per: any = localStorage.getItem("permissions");
        permission.value = JSON.parse(per);
        let user: any = localStorage.getItem("user_info");
        userType.value = JSON.parse(user).type;
      }, 3000);

      if (props.admin) adminMode.value = true;
      else adminMode.value = false;
    });

    const getName = (message) => {
      if (message.user_type.includes("admin")) return translate("operator");
      return message.user.full_name;
    };

    const addNewMessage = () => {
      if (!newMessageText.value) {
        return;
      }
      submitButton1.value?.setAttribute("data-kt-indicator", "on");

      let data = {
        message: newMessageText.value,
      };

      let prefixEndpoint = "";
      if (adminMode.value) prefixEndpoint = "admin/";
      ApiService.post(
        `${prefixEndpoint}ticket/${list.value!.ticket_number}/reply`,
        data
      )
        .then(() => {
          newMessageText.value = "";
          if (list.value!.ticket_number) getTicket(list.value!.ticket_number);
          emit("refreshTickets");
        })
        .catch(({ response }) => {
          if (response && response.data)
            store.commit(Mutations.SET_ERROR, response.data);
        })
        .finally(() => {
          submitButton1.value?.removeAttribute("data-kt-indicator");
        });
    };

    const getTicket = (id) => {
      loading.value = true;
      let prefixEndpoint = "";
      if (adminMode.value) prefixEndpoint = "admin/";
      ApiService.get(`${prefixEndpoint}ticket/${id}`)
        .then(({ data }) => {
          list.value = data.data.ticket;
          loading.value = false;
        })
        .catch(({ response }) => {
          if (response && response.data)
            store.commit(Mutations.SET_ERROR, response.data);
        });
    };

    const closeTicket = (id) => {
      submitButton2.value?.setAttribute("data-kt-indicator", "on");
      ApiService.post(`admin/ticket/${id}/close`, {})
        .then(() => {
          emit("refreshTickets");
          hideModal(modalRef.value);
        })
        .catch(({ response }) => {
          if (response && response.data)
            store.commit(Mutations.SET_ERROR, response.data);
        })
        .finally(() => {
          submitButton2.value?.removeAttribute("data-kt-indicator");
        });
    };

    // const connectToSocket = async () => {
    //   let userId = JwtService.getUserId();
    //   if (JwtService.getUserId()) {
    //     window.Echo.private("user." + userId).listen(
    //       ".refresh_tickets",
    //       (e) => {
    //         if (list.value) {
    //           getTicket(list.value!.ticket_number);
    //         }
    //       }
    //     );
    //   }
    // };

    watch(
      () => props.ticketId,
      (id) => {
        getTicket(id);
      }
    );

    watch(
      () => props.admin,
      (status) => {
        adminMode.value = status;
      }
    );

    watch(el, () => {
      if (el.value) {
        el.value.scrollIntoView({ behavior: "smooth" });
        el.value.scrollTop = el.value.scrollHeight;
      }
    });

    return {
      submitButton1,
      loading,
      newMessageText,
      addNewMessage,
      contacts,
      getTicket,
      adminMode,
      getName,
      statusColor,
      translate,
      list,
      currentUser,
      el,
      modalRef,
      closeTicket,
      submitButton2,
      route,
      permission,
      userType,
    };
  },
});
