import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "table-responsive" }
const _hoisted_2 = { class: "table table-bordered table-row-dashed table-row-gray-300 table-row-bordered table-striped table-hover align-middle gs-2 gy-2 custom-loading-svg" }
const _hoisted_3 = { class: "text-gray-400 fw-bold fs-7 text-uppercase gs-0" }
const _hoisted_4 = { class: "table-sort-asc" }
const _hoisted_5 = { class: "table-sort-asc" }
const _hoisted_6 = { class: "d-flex justify-content-start flex-column" }
const _hoisted_7 = { class: "text-dark fw-bolder text-gray-700 text-hover-primary fs-6 text-nowrap" }
const _hoisted_8 = { class: "fs-7 text-nowrap text-gray-400" }
const _hoisted_9 = { class: "d-flex flex-equal align-items-center" }
const _hoisted_10 = { class: "d-flex flex-column" }
const _hoisted_11 = { class: "fs-8 text-gray-700 text-nowrap" }
const _hoisted_12 = {
  key: 0,
  class: "fs-7 text-gray-500 text-nowrap"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Platform = _resolveComponent("Platform")!
  const _component_Date = _resolveComponent("Date")!
  const _component_Status = _resolveComponent("Status")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", _hoisted_2, [
      _createElementVNode("thead", null, [
        _createElementVNode("tr", _hoisted_3, [
          _createElementVNode("th", _hoisted_4, _toDisplayString(_ctx.translate("subscriptions")), 1),
          _createElementVNode("th", _hoisted_5, _toDisplayString(_ctx.translate("platforms")), 1),
          _createElementVNode("th", null, _toDisplayString(_ctx.translate("package")) + " | " + _toDisplayString(_ctx.translate("end")), 1)
        ])
      ]),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.botList, (item, index) => {
          return (_openBlock(), _createElementBlock("tr", { key: index }, [
            _createElementVNode("td", null, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("span", _hoisted_7, _toDisplayString(item.name), 1),
                _createElementVNode("span", _hoisted_8, _toDisplayString(item.bot_id), 1)
              ])
            ]),
            _createElementVNode("td", {
              class: _normalizeClass(["text-nowrap", [
                item.pay_status === 'active'
                  ? ''
                  : 'highlight-overlay bg-light-warning',
              ]])
            }, [
              _createElementVNode("div", _hoisted_9, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.platforms, (plat, platIndex) => {
                  return (_openBlock(), _createBlock(_component_Platform, {
                    key: platIndex,
                    class: "ms-1",
                    platform: plat.platform,
                    avatar: plat.avatar,
                    logo: plat.platform_avatar,
                    number: plat.platform_number,
                    status: plat.status,
                    secretary: plat.enable_secretary,
                    botId: item.bot_id,
                    "package-support": 
                    _ctx.checkPackagePlatform(item.package.platforms, plat.platform)
                  ,
                    "show-btns": false,
                    online: "yes"
                  }, null, 8, ["platform", "avatar", "logo", "number", "status", "secretary", "botId", "package-support"]))
                }), 128))
              ])
            ], 2),
            _createElementVNode("td", null, [
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("span", _hoisted_11, _toDisplayString(item.package?.package?.full_name), 1),
                (item.package && item.package.expired_date)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_12, [
                      _createVNode(_component_Date, {
                        date: item.package.expired_date
                      }, null, 8, ["date"]),
                      (
                      new Date(item.package?.expired_date.gregorian) <
                      new Date()
                    )
                        ? (_openBlock(), _createBlock(_component_Status, {
                            key: 0,
                            status: "expired"
                          }))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ])
          ]))
        }), 128))
      ])
    ])
  ]))
}