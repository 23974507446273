
import Platform from "@/components/Platform.vue";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { checkPackagePlatform } from "@/core/services/HelpersFun";

export default defineComponent({
  name: "bots-user-history",
  props: {
    botList: {
      type: Object,
      default: null,
    },
  },
  components: {
    Platform,
  },
  setup() {
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };

    const checkNotActivePackagePlatform = (packagePlatforms, platforms) => {
      const connectedPlatforms =
        platforms !== null && platforms.length > 0
          ? platforms.map((pl) => {
              return pl.platform;
            })
          : [];

      if (connectedPlatforms !== null && packagePlatforms.length > 0)
        return packagePlatforms.filter((x) => !connectedPlatforms.includes(x));
      return connectedPlatforms;
    };

    return {
      translate,
      checkNotActivePackagePlatform,
      checkPackagePlatform,
    };
  },
});
