import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_checkbox_group = _resolveComponent("el-checkbox-group")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_checkbox_group, {
      modelValue: _ctx.checkList,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.checkList) = $event))
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.all_permissions, (per) => {
          return (_openBlock(), _createBlock(_component_el_checkbox, {
            class: "col-5",
            key: per.id,
            label: per,
            value: per
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.translate(per)), 1)
            ]),
            _: 2
          }, 1032, ["label", "value"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("button", {
        class: "btn text-nowrap btn-primary ms-3 mb-3 mb-md-0 px-4 py-3",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.save()))
      }, _toDisplayString(_ctx.translate("save")), 1)
    ])
  ], 64))
}