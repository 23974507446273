
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import platform from "@/components/Platform.vue";
import { checkPackagePlatform } from "@/core/services/HelpersFun";
import { platforms } from "@/store/pinia/Platforms";
import { storeToRefs } from "pinia";

export default defineComponent({
  name: "secretary-user-history",
  props: {
    list: {
      type: Object,
      default: null,
    },
  },
  components: {
    platform,
  },
  setup() {
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };

    const platformHolder = platforms();
    const { platformsList } = storeToRefs(platformHolder);

    return {
      translate,
      checkPackagePlatform,
      platformsList,
    };
  },
});
