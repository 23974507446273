
import { defineComponent, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import ApiService from "@/core/services/ApiService";
import { useRoute } from "vue-router";
import { ElNotification } from "element-plus/es";

export default defineComponent({
  name: "main-information-user-history",
  props: {
    user_permissions: {
      type: Array,
      default: null,
    },
  },
  setup(props) {
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };

    const route = useRoute();
    const userId = ref(route.params.id);

    const checkList = ref();
    const all_permissions = ref();
    const get_all_permissions = () => {
      ApiService.get("admin/permissions")
        .then(({ data }) => {
          all_permissions.value = data.data.permissions;
        })
        .catch(({ response }) => {
          return;
        })
       
    };

    const save = () => {
      ApiService.post(`admin/user/${userId.value}/change-permissions`, {
        permissions: checkList.value,
      })
        .then(({ data }) => {
          ElNotification({
            title: translate("pay attention"),
            message: data.message,
            type: "success",
          });
        })
        .catch(({ response }) => {
          return;
        })
      
    };

    onMounted(() => {
      checkList.value = [];
      get_all_permissions();
    });

    watch(props, () => {
      checkList.value = props.user_permissions;
    });

    return {
      translate,
      all_permissions,
      checkList,
      save,
    };
  },
});
